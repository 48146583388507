'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category').detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').removeClass('show');
    $(element).removeClass('show');
};

var trapMobileNavFocus = function () {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector('.main-menu');

    if (!modal) {
        return;
    }

    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    firstFocusableElement.focus();
    document.addEventListener('keydown', function (e) {
        if (modal.classList.contains('in')) {
            if (e.key === 'Escape') {
                $('.close-button').trigger('click');
            }
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    e.preventDefault();
                }
            }
        }
    });

    firstFocusableElement.focus();
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'absolute';
    };

    function updateNavbarToggler() {
        $('.navbar-toggler').attr('aria-expanded', $('#sg-navbar-collapse').hasClass('in'));
    }

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('hide');
    });

    $(document).on('keyup', function (evt) {
        if (evt.keyCode == 27) {
            $('.nav-item.dropdown').each(function () {
                if (isDesktop(this)) {
                    $(this).removeClass('show');
                    $(this).children('.dropdown-menu').removeClass('show');
                    $(this).children('.dropdown-menu').attr('aria-hidden', 'true');
                    $(this).children('.nav-link').attr('aria-expanded', 'false');
                }
            });
        }
    });

    keyboardAccessibility(
        '.main-menu .nav-link, .main-menu .dropdown-link',
        {
            32: function (menuItem) {
                // space
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    $('.navbar-nav .nav-item')
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show')
                        .attr('aria-hidden', 'true');

                    menuItem.addClass('show').children('.nav-link').attr('aria-expanded', 'true');
                    menuItem.children('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                }
            },
            13: function (menuItem) {
                // enter
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    $('.navbar-nav .nav-item')
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show')
                        .attr('aria-hidden', 'true');

                    menuItem.addClass('show').children('.nav-link').attr('aria-expanded', 'true');
                    menuItem.children('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                }
            },
            40: function (menuItem) {
                // down
                if (menuItem.hasClass('nav-item') && menuItem.hasClass('show')) {
                    // top level
                    $('.navbar-nav .show').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.addClass('show');
                    menuItem.children('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                }
            },
            39: function (menuItem) {
                // right
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown-item')) {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                }
            },
            38: function (menuItem) {
                // up
                var navItemDropdown = menuItem.parent().parent();
                var navLink = navItemDropdown.children('.nav-link');
                var dropdownMenu = navItemDropdown.children().eq(1);
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else if (menuItem.prev().length === 0) {
                    navItemDropdown.removeClass('show');
                    dropdownMenu.removeClass('show');
                    dropdownMenu.attr('aria-hidden', 'true');
                    navLink.attr('aria-expanded', 'false').focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) {
                // left
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            27: function (menuItem) {
                // escape
                var parentMenu = menuItem.hasClass('show') ? menuItem : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link').attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            },
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) .nav-link[data-toggle="dropdown"] ').on('click', function (e) {
        trapMobileNavFocus();
        var $this = $(this); // set pointer to the actual link
        if (!isDesktop($this)) {
            $('html, body').animate({ scrollTop: '0' }, 100);
            $('.modal-background').show();
            $('.menu-background').addClass('active');
            // copy parent element into current UL
            var link = $this
                .clone()
                .removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            var closeMenu = $('<li class="nav-menu"></li>');
            closeMenu.append($('.close-menu').first().clone());
            closeMenu.find('.back span').text($this.attr('data-back-text'));
            closeMenu.append(link);
            closeMenu.find('a').trigger('focus');
            var dropmenu = $this.parent().children('.dropdown-menu');
            !dropmenu.children('.nav-menu').length && dropmenu.prepend(closeMenu);
            dropmenu.attr('aria-hidden', 'false');
            dropmenu.removeAttr('inert', '');
            // copy navigation menu into view
            var $navItems = $('.nav-item');
            $navItems.attr('aria-hidden', 'true');
            $navItems.attr('inert', '');
            //Need a reference to the previous button clicked to avoid a focus stuck loop
            var buttonParentClicked = $(this).parents('li').children('button');
            var navAccountMenu = $('.nav-account-menu');
            //Hiding of the tab itself as hiding the unordered list does not hide the image
            $('.tab-shoe-dept').attr('style', 'visibility:hidden');
            $('.tab-shoe-dept').attr('aria-hidden', 'true');
            $('.tab-shoe-dept').attr('inert', '');
            navAccountMenu.attr('style', 'visibility:hidden');
            $this.parent().children('ul').attr('style', 'visibility:visible');
            var childList = $this.parent().children('ul');
            for (let index = 0; index < childList.children().length; index++) {
                $(childList.children()[index]).attr('style', 'visibility: visible');
            }
            $this.parent().addClass('show');
            $this.parent().attr('aria-hidden', 'false');
            $this.parent().removeAttr('inert', '');
            var previousButton = $(e.currentTarget);
            var headerBackButton = $('.close-menu').first();
            headerBackButton.attr('aria-hidden', 'true');
            previousButton.attr('aria-hidden', 'true');
            headerBackButton.attr('inert', '');
            previousButton.attr('inert', '');
            var activeChildren = $this.parent().children('.dropdown-menu');
            var index = activeChildren.children().length - 1;
            var lastChild = activeChildren.children().get(index);
            $this.attr('aria-expanded', 'true');
            closeMenu.find('a').on('click', function () {
                $this.parent().children('.dropdown-menu').attr('aria-hidden', 'true');
                $this.parent().children('.dropdown-menu').attr('inert', '');
                $navItems.attr('aria-hidden', 'false');
                $navItems.removeAttr('inert');
                //Reversing all the hiding of the parent buttons from earlier once user goes to the previous menu
                $navItems.attr('style', 'visibility:visible');
                navAccountMenu.attr('style', 'visibility:visible');
                $('.tab-shoe-dept').attr('style', 'visibility:visible');
                $('.tab-shoe-dept').attr('aria-hidden', 'false');
                $('.tab-shoe-dept').removeAttr('inert');
                //Cycling through all the child menu items and rehiding them
                $this.parent().children('ul').attr('style', 'visibility:hidden');
                for (let index = 0; index < childList.children().length; index++) {
                    $(childList.children()[index]).attr('style', 'visibility: hidden');
                }
                //Setting focus back to the previous button clicked
                buttonParentClicked[0].focus();
                previousButton.attr('aria-hidden', 'false');
                headerBackButton.attr('aria-hidden', 'false');
                previousButton.removeAttr('inert');
                headerBackButton.removeAttr('inert');
            });
            $(lastChild).on('focusout', function () {
                closeMenu.find('a').trigger('focus');
            });
            let navMenu = $('[id$=' + e.currentTarget.id + '] li.nav-menu');
            !navMenu.find('.nav-link').length && navMenu.append($('.top-category button'));
            e.preventDefault();
        }
    });

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]').on('click', function () {
        if (isDesktop(this)) {
            if ($(this).attr('aria-expanded') == 'true') {
                var $parent = $(this).parent();
                $parent.removeClass('show');
                $parent.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                $parent.children('.nav-link').attr('aria-expanded', 'false');
            } else {
                var eventElement = this;

                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this)
                            .find('.show')
                            .each(function () {
                                clearSelection(this);
                            });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('ul.dropdown-menu').attr('aria-hidden', 'true');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).siblings('.dropdown-menu').attr('aria-hidden', 'false');
                $(this).attr('aria-expanded', 'true');
            }
        }
    });

    $('.mobile-menu.close-button').on('click', function (e) {
        $('.mobile-menu.close-button').attr('aria-hidden', 'true');
        closeMobileMenu(e, this);
    });
    function closeMobileMenu(e, el) {
        e && e.preventDefault();
        $('header').removeClass('position-static');
        $('body').removeClass('no-scrollable');
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
        $('.menu-background').removeClass('active');
        $('.main-menu').attr('aria-hidden', 'true');
        $('.close-menu').hide();
        $('.menu-group').hide();
        $('.header.container').removeAttr('inert');
        updateNavbarToggler();
        backgroundEls.attr('aria-hidden', 'false').removeAttr('inert');
        $('.navbar-toggler').focus();

        // Ensure tab is visible when closing submenu
        // and switching from mobile to desktop via browser resize
        if ($('.tab-shoe-dept').attr('aria-hidden') == 'true') {
            $('.tab-shoe-dept').attr('style', 'visibility:visible');
            $('.tab-shoe-dept').attr('aria-hidden', 'false');
            $('.tab-shoe-dept').removeAttr('inert');
        }

        // Show main menu if user has closed mobile menu then resized to desktop
        $('.nav-main-menu-container').show();
    }

    $('.navbar').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    if (!isDesktop($('.navbar'))) {
        $('.main-menu').attr('aria-hidden', 'true').attr('inert', '');
    }

    trapMobileNavFocus();

    //below elements need aria-hidden and inert set for Voiceover on iOS to work
    var backgroundEls = $(
        '.header-banner, .header, .search-mobile, #maincontent, footer, .back-to-top, .skip-link, .cid-cookie_hint'
    );

    $('.navbar').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: '0' }, 100);
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.main-menu').attr('aria-hidden', 'true').attr('inert', '').removeClass('in');
        $('body').addClass('no-scrollable');
        $('header').addClass('position-static');
        $('.modal-background').hide();
        $('.menu-background').removeClass('active');
        $('.navbar-toggler').focus();
        $('.close-menu').hide();
        $('.menu-group').hide();
        updateNavbarToggler();
        backgroundEls.attr('aria-hidden', 'false').removeAttr('inert');
    });

    $('.navbar-toggler').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: '0' }, 100);
        $('.multisite-tabs>ul>li>a').show();
        $('.close-menu').show();
        $('.mobile-menu.close-button').attr('aria-hidden', 'false');
        $('.menu-group').show();
        $('.main-menu').toggleClass('in').attr('aria-hidden', 'false').removeAttr('inert');
        $('body').addClass('no-scrollable');
        $('header').addClass('position-static');
        $('.modal-background').show();
        $('.modal-background').on('click', closeMobileMenu);
        $('.menu-background').addClass('active');
        $('.tab-shoe-show a').first().focus();
        updateNavbarToggler();
        backgroundEls.attr('aria-hidden', 'true').attr('inert', '');
        $('.nav-item').first().children().first().focus();
        trapMobileNavFocus();
    });

    keyboardAccessibility(
        '.header-login .user',
        {
            40: function ($popover) {
                // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.children('a').first().next().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) {
                // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function ($popover) {
                // escape
                $(this).focus();
                $popover.removeClass('show');
            },
            9: function ($popover) {
                // tab
                $popover.removeClass('show');
            },
        },
        function () {
            var $popover = $('.user .popover');
            if (!$popover.hasClass('show')) {
                $popover.addClass('show');
            }
            return $popover;
        }
    );

    $('.header-login .user').on('mouseenter focusin', function () {
        if ($('.header-login .user .popover').length > 0) {
            $('.header-login .user .popover').addClass('show');
        }
    });

    $('.header-login .user').on('mouseleave', function () {
        $('.header-login .user .popover').removeClass('show');
    });

    $('.refinement-heading')
        .on('keydown', function (e) {
            var key = e.which;
            if (key == 13 || key == 32) {
                var expanded = $(this).attr('aria-expanded');
                $(this).attr('aria-expanded', expanded == 'true' ? 'false' : 'true');
            } else if (key == 9) {
                $(this).next().find('li').eq(0).focus();
            }
        })
        .on('click', function () {
            var expanded = $(this).attr('aria-expanded');
            $(this).attr('aria-expanded', expanded == 'true' ? 'false' : 'true');
        });

    var pageQs = new URLSearchParams($('.page').data('querystring'));
    var pagecgid = pageQs && pageQs.get('cgid') != null ? pageQs.get('cgid') : null;
    if (pagecgid && pagecgid.length > 0) {
        var cgid = pagecgid[1];
        $('a#' + cgid).attr('aria-current', 'page');
        $('<span class="visually-hidden">, current section</span>').insertAfter('a#' + cgid + ' .menu-item-label');
    }

    //Add class 'visually-hidden' on Category Title Nav Menu - Mobile Nav
    $('body').on('click', '.nav-item', function () {
        if (!isDesktop(this)) {
            var $parentBack = $(this).find('.back');
            $parentBack.find('span').addClass('visually-hidden');
        }
    });
};
