'use strict';

var regex = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
var cookies = require('./cookie');

/**
 * @function
 * @description Validates a given email address against the email regex
 * @param {String} value The email address which will be validated
 */
var validateEmail = function (value) {
    var isValid = regex.email.test($.trim(value));
    return isValid;
};

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showNewsletterSignupModal() {
    if (!$('.newsletter-signup-modal').data('caonline')) {
        return;
    }

    var urlContent = $('.newsletter-signup-modal').data('url');
    var urlContentSubscribe = $('.newsletter-signup-modal').data('subscribe');
    var urlContentThanks = $('.newsletter-signup-modal').data('thanks');
    var modalClosed = $('.newsletter-signup-modal').data('modal-closed');

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal show" id="newsletter-signup-modal" role="dialog" style="display: block;">' +
        '<div class="modal-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="button-wrapper">' +
        '<button type="button" class="close" data-modal-closed="' +
        modalClosed +
        '">' +
        'X' +
        '</button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '</div>' +
        '</div>';
    $.spinner().start();
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('#newsletter-signup-modal .modal-body').html(response);
            $('#newsletter-signup .newsletter-modal-submit').click(function (e) {
                e.preventDefault();
                var form = $(this).closest('form');
                var email = form.find('input[name=hpEmailSignUp]');
                if (!validateEmail(email.val())) {
                    email.addClass('is-invalid');
                    return;
                } else {
                    email.removeClass('is-invalid');
                }
                var url = urlContentSubscribe;
                url = url + '?hpEmailSignUp=' + email.val() + '&newsletterModalSubmitted=true';

                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        if (!data.error) {
                            $.ajax({
                                url: urlContentThanks,
                                type: 'get',
                                dataType: 'html',
                                success: function (response) {
                                    $('#newsletter-signup-modal .modal-body').html(response);
                                },
                                error: function () {
                                    console.log('fail');
                                },
                            });
                        } else {
                            email.addClass('is-invalid');
                        }
                    },
                    error: function () {
                        console.log('fail');
                    },
                });
            });
        },
        error: function () {
            $('#newsletter-signup-modal').remove();
        },
    });

    $('#newsletter-signup-modal .button-wrapper button.close').click(function (e) {
        e.preventDefault();
        var modalClosed = $(this).data('modal-closed');
        $.ajax({
            url: modalClosed,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('#newsletter-signup-modal .modal-body').remove();
                $('#newsletter-signup-modal').remove();
                $.spinner().stop();
            },
            error: function () {
                $('#newsletter-signup-modal .modal-body').remove();
                $('#newsletter-signup-modal').fadeOut('fast').remove();
                $.spinner().stop();
            },
        });
    });
}

module.exports = function () {
    if ($('.newsletter-modal-closed').length === 0 && $('.newsletter-signup-modal').hasClass('api-true')) {
        if (!cookies.getCookie('shoeshow_newsletter_modal_closed')) {
            showNewsletterSignupModal();
        }
    }

    if ($('.newsletter-signup-modal').hasClass('api-true')) {
        $('.newsletter-signup-modal').click(function () {
            if (!cookies.getCookie('shoeshow_newsletter_modal_closed')) {
                showNewsletterSignupModal();
            }
        });
    }
};
