'use strict';
var setdatalayer = require('../gtm/setdatalayer');

module.exports = function () {
    var throttleScroll,
        $backToTop = $('.back-to-top');

    $backToTop.click(function () {
        $('html, body').animate(
            {
                scrollTop: 0,
            },
            500
        );
        $('.tab-shoe-show a').focus();
    });

    $(window).scroll(function () {
        clearTimeout(throttleScroll);
        throttleScroll = setTimeout(function () {
            if (window.pageYOffset > 0) {
                $backToTop.addClass('show');
            } else {
                $backToTop.removeClass('show');
            }
        }, 100);
    });

    $('.email-signup-form .newsletter-submit').click(function (e) {
        e.preventDefault();
        var $success = $('.email-signup-form .success-message');
        $success.removeClass('error');
        $success.text('');

        var $form = $('.email-signup-form #newsletter-signup');
        var filter = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
        var email = $('#hpEmailSignUp');

        if (email.val().trim() !== '') {
            if (!filter.test(email.val())) {
                $success.addClass('error');
                $success.text('Please match the requested format: example@domain.com');
            } else {
                $.ajax({
                    url: $form.data('action'),
                    method: 'GET',
                    data: $form.serialize(),
                    success: function (data) {
                        $success.text("Thank you! You've been successfully signed up.");
                        setdatalayer.EmailSignUp(email.val());
                    },
                    error: function () {
                        setdatalayer.EmailSignUp(email.val());
                    },
                });
            }
        } else {
            $success.addClass('error');
            $success.text('Please provide an email');
        }
    });
};
