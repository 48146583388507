'use strict';

var scrollHelper = require('./scroll');

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this)
            .find('input, select')
            .each(function () {
                if (!this.validity.valid) {
                    $(this).trigger('invalid', this.validity);
                }
            });
    }
    if (!valid) {
        $(this).trigger('invalid');
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid, .form-control-label.is-invalid').removeClass('is-invalid');
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            var $this = $(this);
            if (!this.validity.valid) {
                var id = $this.attr('id');
                var label = $('label[for=' + id + ']')
                    .text()
                    .trim();
                var validationMessage = label + ' field is required.';
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').find('.form-control-label').addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = validationMessage.replace('.', ':');
                    validationMessage += ' ' + $(this).data('pattern-mismatch');
                }
                if (this.validity.patternMismatch && $(this).data('parse-error')) {
                    validationMessage = $(this).data('parse-error');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);

                $(this).closest('form').trigger('invalid');
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            var form = $(this).parents('form');

            // clear all errors when trying to submit the form
            clearForm(form);

            // initializing event to scroll to first error field
            $(form).one('invalid', function (e) {
                if ($(this).find('.is-invalid').length > 0) {
                    scrollHelper.scrollTo($('.is-invalid').first(), 30);
                    $('.is-invalid').first().focus();
                }
            });
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm,
    },
};
