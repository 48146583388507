'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

function areCookiesEnabled() {
    try {
        document.cookie = 'cookietest=1';
        var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
        return cookiesEnabled;
    } catch (e) {
        return false;
    }
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = name + '=' + value + '; expires=' + date.toGMTString() + '; path=/;SameSite=None;secure';
    } else {
        document.cookie = name + '=' + value;
    }
}

function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function deleteCookie(name) {
    createCookie(name, '', -1);
}

function manageCookiesAlert() {
    if (!areCookiesEnabled()) {
        $('.error-no-cookies-container').show();
    } else {
        // if cookie exists, alert stays hidden until cookies are deleted/cleared
        if (readCookie('ssCookieMsg') == 'Cookie Agreement Acknowledged') {
            $('.cookie-warning-messaging').hide();
        } else {
            $('.cookie-warning-messaging').show();
            $('#cookieAlert').focus();

            $('.cookie-warning-messaging').on('keydown', function (e) {
                if (e.key === 'Escape') {
                    createCookie('ssCookieMsg', 'Cookie Agreement Acknowledged', 180);
                    // return focus when modal is closed
                    $('.cookie-warning-messaging').off();
                    $('.cookie-warning-messaging').hide();
                }
            });
        }

        //onclick of the cookie alert button the cookie is created and assigned a value the alert message is hidden
        $(':button').click(function () {
            if (this.id == 'cookieAlert') {
                createCookie('ssCookieMsg', 'Cookie Agreement Acknowledged', 180);
                // return focus when modal is closed
                $('.cookie-warning-messaging').off();
                $('.cookie-warning-messaging').hide();
            }
        });
    }
}

module.exports = {
    getCookie: getCookie,
    createCookie: createCookie,
    readCookie: readCookie,
    deleteCookie: deleteCookie,
    manageCookiesAlert: manageCookiesAlert,
};
