'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var formHelpers = require('../checkout/formErrors');

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isTabletSearch(scope) {
    var screenWidth = screen.width;
    return screenWidth > 576 && screenWidth <= 768;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var iconSearch = $('.search-mobile .fa-search');
    var iconSearchClose = $('.search-mobile .fa-close');

    if (action === 'close') {
        iconSearch.hide();
        iconSearchClose.show();
    } else {
        iconSearch.show();
        iconSearchClose.hide();
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if ($(scope).scrollTop() + $(scope).innerHeight() >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        if (isTabletSearch(scope)) {
            $suggestions.css('height', 'unset');
        } else {
            $suggestions.css('top', top + outerHeight);
        }

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner(false).stop();

    if (!(typeof response === 'object')) {
        $('body').append("<div id='overlay' class='veil'><div class='underlay'></div></div>");
        $suggestionsWrapper.append(response).show();
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            // toggleSuggestionsIcon('close');
            applyModals(this);
        }
    } else {
        $('#overlay').detach();
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    $.spinner(false).start();
    $.ajax({
        context: scope,
        url: endpoint + encodeURIComponent($(scope).val()),
        method: 'GET',
        success: processResponse,
        error: function () {
            $.spinner(false).stop();
        },
    });
    formHelpers.clearPreviousErrors('.site-search');
}

module.exports = function () {
    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);

        // Do not fetch suggestions again if user has pressed the escape key
        $(this).on('keyup click', function (e) {
            if (e.key !== 'Escape') {
                debounceSuggestions(this, e);
            }
        });
    });

    var $body = $('body');

    $body.on('click', function (e) {
        if (!$('.suggestions').has(e.target).length && !$(e.target).hasClass('search-field')) {
            $('.suggestions').hide();
        }
    });

    // Ensure search suggestions are accessible; can close via escape key
    $body.on('keydown', function (e) {
        if (e.key === 'Escape') {
            if ($(e.target).hasClass('search-field') ||
                $(e.target).hasClass('search-icon') ||
                $(e.target).hasClass('suggestion-link')
            ) {
                $('.suggestions').hide();
            }
        }
    });

    $body.on('click touchend', '.search-mobile .fa-close', function (e) {
        $('.suggestions').hide();
        toggleSuggestionsIcon('search');
        tearDownSuggestions();
    });

    $body.on('click', '.reset-filters', function () {
        $('.filter-bar a.reset').click();

        var backgroundEls = $(
            '.header-banner, .header, .search-mobile, #maincontent, footer, .back-to-top, .skip-link, .cid-cookie_hint, .search-results-main-content'
        );
        $('header').removeAttr('inert');
        $('.header.container').removeAttr('inert');
        backgroundEls.attr('aria-hidden', 'false').removeAttr('inert');
    });

    if ($('.refinement:not(.refinement-category) .selected').length > 0) {
        $('.reset-filters').removeClass('disabled');
    }

    $('[name=simpleSearch]').on('submit', function (e) {
        if ($(this).find('.search-field').val().trim() == '') {
            // Do not submit search form if input is blank or whitespace
            return false;
        }
    });

    $('.fa-search').on('click touchstart', function () {
        $(this).closest('[name=simpleSearch]').submit();
    });

    $('.search-icon-button').on('click touchstart', function () {
        window.scrollTo(0, 0);
        $('.container.header').removeClass('searchbox-closed');
        $('.search-mobile').show();
        $('.search-icon-close').show();
        $('input.search-field').last().focus();
    });
    $('.search-icon-close.fa.fa-close').on('click touchstart', function (e) {
        $('.search-mobile').hide();
        $('.container.header').addClass('searchbox-closed');
        $('#overlay').detach();
        e.stopPropagation();
        e.preventDefault();
    });
    $('.clear-search').on('click touchstart', function () {
        $('.search-field').val('');
        $('.clear-search').hide();
    });
    $('.search-field').keypress(function () {
        if ($(this).val()) {
            $('.clear-search').show();
        }
    });
};
