/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
'use strict';

var cart = require('../cart/cart');

function updateAriaLabel(message) {
    var base = $('.minicart-link').attr('aria-label').split('|')[0];
    $('.minicart-link').attr('aria-label', base + '| ' + message);
}

module.exports.updateAriaLabel = updateAriaLabel;

module.exports = function () {
    cart();

    var $minicart = $('.minicart');

    $minicart.on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity, .minicart-tablet .minicart-quantity').text(count.quantityTotal);
            $('.minicart-mobile .minicart-quantity').text(count.quantityTotal);
            updateAriaLabel(count.cart.resources.numberOfItems);
        }

        if (count && count.mobileMiniCartHTML) {
            $('.minicart-container').html(count.mobileMiniCartHTML);
            miniCartInit();
        }
    });

    function showMiniCart() {
        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $minicart.data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                if ($(data).find('.product-summary .row').length > 0) {
                    // Returned data has product line items; display minicart
                    $('.minicart .popover').append(data);
                    $.spinner().stop();
                    trapFocus();
                } else {
                    // Session expired; minicart no longer valid so clean the desktop header and mobile nav menus
                    $('.minicart .popover').removeClass('show');
                    $.spinner().stop();

                    $('.minicart-link .minicart-quantity').text('0');

                    var $headerLoginArea = $('.header-login');
                    $headerLoginArea.find('.reward-points').removeClass('reward-points-loggedin');
                    $headerLoginArea
                        .find('.reward-points span')
                        .removeClass('reward-points-total')
                        .text('Reward Points');

                    var myAccountURL = $headerLoginArea.find('.user a').attr('href');
                    $headerLoginArea
                        .find('.user')
                        .empty()
                        .append(
                            '<a class="text-white" href="' +
                                myAccountURL +
                                '">' +
                                '<span class="user-message">Sign In</span>' +
                                '</a>'
                        );

                    var $mobileMenu = $('.nav-account-menu');
                    $mobileMenu
                        .find('.nav-item-user')
                        .empty()
                        .append(
                            '<a class="nav-link" href="' +
                                myAccountURL +
                                '">' +
                                '<span class="user-message">Sign In</span>' +
                                '</a>'
                        );
                    $mobileMenu
                        .find('.nav-item-rewards-loggedin')
                        .removeClass('nav-item-rewards-loggedin')
                        .addClass('nav-item-rewards-guest')
                        .find('span')
                        .removeClass('reward-points-total')
                        .text('Reward Points');
                    $mobileMenu.find('.nav-item-logout').remove();
                }
            });
        }
    }

    function trapFocus() {
        const focusableElements = 'button, [href], input, select, [tabindex]:not([tabindex="-1"])';
        const modal = document.querySelector('.minicart');
    
        if (!modal) {
            return;
        }
    
        const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
        const focusableContent = modal.querySelectorAll(focusableElements);
        const lastFocusableElement = focusableContent[focusableContent.length - 1];
    
        firstFocusableElement.focus();
        document.addEventListener('keydown', function (e) {
            if (e.key === 'Escape') {
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
                document.activeElement.blur();
            }

            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    e.preventDefault();
                }
            }
        });
    
        firstFocusableElement.focus();
    };

    $minicart.on('mouseenter  touchstart', showMiniCart);

    $('.minicart-link').on('focus', showMiniCart);

    $('body').on('touchstart click', function (e) {
        if ($minicart.has(e.target).length <= 0) {
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }
    });

    $(document).on('keyup', function (e) {
        if (e.keyCode == 27 && $('.minicart .popover').hasClass('show')) {
            $('.minicart .popover').removeClass('show');
            $.spinner().stop();
        }
    });

    $minicart.on('mouseleave', function (event) {
        if (
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').empty();
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('.minicart-mobile, .minicart-tablet').on('click', function (e) {
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        if (count !== 0) {
            $('#minicart-flyout').show();
            e.stopPropagation();
            e.preventDefault();
            var headerFooterSpace =
                $('#minicart-flyout .minicart-footer').height() + $('#minicart-flyout .titlebar').height();
            var flyoutSize = $('#minicart-flyout .container.cart').height();
            $('body').addClass('no-scrollable');
            $('.product-summary').height(flyoutSize - headerFooterSpace);
            $('.remove-btn.remove-product span').text('Remove');
            $('#minicart-flyout .close-button').on('click', function (e) {
                e.preventDefault();
                $('body').removeClass('no-scrollable');
                $('#minicart-flyout').hide();
            });
        }
    });

    const miniCartInit = function () {
        $('#minicart-flyout').on('touchmove', function (e) {
            console.log(e.target);
            e.stopPropagation();
        });
    };

    miniCartInit();
};
