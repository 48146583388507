'use strict';
function addAccessibilityButtons(afterRefinement = false) {
    if ($(window).width() <= 794) {
        // hide desktop footer list and show mobile footer list
        $('.mobile-footer-list').show();
        $('.desktop-footer-list').hide();
    } else {
        $('.mobile-footer-list').hide();
        $('.desktop-footer-list').show();
    }

    var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

    sizes.forEach(function (size) {
        var selector =
            '.collapsible-' +
            size +
            ' .title, .collapsible-' +
            size +
            '>.card-header, .collapsible-' +
            size +
            '>.refinement-button-heading>.card-header';

        $(selector).each(function (i) {
            var $menuHeader = $(this);
            if ($menuHeader.hasClass('refinement-heading')) {
                // don't add more than one button
                // skip if a button has already been added
                if ($menuHeader.find('button').length !== 0) {
                    return;
                }
            }
            // if on mobile screen size or a refinement button
            var containerSize = $menuHeader.parents('.container').css('width');
            if (Number(containerSize.replace('px', '')) <= 560 || $(this).hasClass('refinement-heading')) {
                // need to add button for accessiblility
                if ($(this).find('button').length === 0) {
                    var text = $(this).text();
                    var tabIndex = -1;
                    if ($(this).hasClass('footer-title') || $(this).hasClass('collapsible-title')) {
                        tabIndex = 0;
                        $(this).attr('tabindex', -1);
                    }
                    $(this).html(
                        '<button class="collapsible-btn" aria-expanded= "false" tabindex="' +
                            tabIndex +
                            '">' +
                            text +
                            '</button>'
                    );
                    $menuHeader = $(this).find('button');

                    if ($(this).hasClass('footer-title') || $(this).hasClass('collapsible-title')) {
                        $(this).parents('.footer-item').attr('role', 'listitem');
                        $(this).parents('.row').attr('role', 'list');
                        $(this).removeAttr('role');
                        $($menuHeader).on('click', function (e) {
                            e.preventDefault();
                            $(this).attr('aria-expanded', function (i, attr) {
                                return attr == 'false' ? 'true' : 'false';
                            });
                        });
                    }
                }

                var id = 'collapsible-item-' + i;
                var menu = $(this)
                    .parents('.collapsible-' + size)
                    .find('.card-body, .content')
                    .first();
                if (!$menuHeader[0].hasAttribute('aria-controls') && !menu[0].hasAttribute('id')) {
                    $menuHeader.attr('aria-controls', id);
                    menu.attr('id', id);
                }
            }
            // else not mobile screen size or refinement buttons (return footer buttons to normal sections when browser resizes to desktop size)
            else {
                if ($(this).find('button').length !== 0) {
                    var text = $(this).text();
                    $menuHeader.text(text);
                    $menuHeader.children().first().remove();
                }
                if (!$menuHeader.hasClass('nofocus-desktop')) {
                    $menuHeader.attr('tabindex', '0');
                    $menuHeader.attr('aria-label', $(this).text());
                }
                $menuHeader.removeAttr('role');
                $(this).parents('.footer-item').removeAttr('role');
                $(this).parents('.row').removeAttr('role');
            }
            // end selector forEach
        });

        // wire up click events once button has been built
        if (!afterRefinement) {
            $('body').on('click', selector, function (e) {
                e.preventDefault();
                var parent = $(this).parents('.collapsible-' + size);
                parent.toggleClass('active');
                var expanded = $(this).attr('aria-expanded') === 'true' ? 'true' : 'false';
                $(this).attr('aria-expanded', expanded);
            });
        } else {
            $('body').off('click', selector);
            $('body').on('click', selector, function (e) {
                e.preventDefault();
                var parent = $(this).parents('.collapsible-' + size);
                parent.toggleClass('active');
                // This is here to make sure the buttons still announce properly after a refinement is selected
                parent
                    .find('button[aria-expanded]')
                    .first()
                    .attr('aria-expanded', function (i, attr) {
                        return attr == 'false' ? 'true' : 'false';
                    });
            });
        }
        // end size forEach
    });
}
window.onresize = addAccessibilityButtons;
module.exports = {
    addAccessibilityButtons: addAccessibilityButtons,
};
